<template lang="html">
  <div v-if="!passwordChanged">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(handlePasswordReset)" class="Form">
        <div class="Form__wrapper">
          <div v-if="passwordChangeFailed" class="Card Card--warning mb-16">
            Password wasn't changed. Try sending the email one more time.
          </div>
          <h1 class="title-3">Password Recovery</h1>
          <p class="text-xlg text-semi">Create new password for your account</p>
          <formly-form
            :form="form"
            :model="model"
            :fields="fields"
            tag="div"
            class="Form__group"
          ></formly-form>
        </div>
        <button class="btn btn--primary btn--min">Reset password</button>
      </form>
      <router-link
        :to="{ name: 'register-player' }"
        class="clickable nav-link block mt-16 text-md"
        >Don't have an account? Register here</router-link
      >
      <router-link
        :to="{ name: 'login' }"
        class="clickable nav-link block mt-8 text-md"
        >Log in</router-link
      >
    </ValidationObserver>
  </div>
  <div v-else>
    <h1 class="title-3">Password changed!</h1>
    <p class="text-xlg text-semi">
      You can now log in using your new credentials!
    </p>
    <router-link :to="{ name: 'login' }" class="btn btn--outlined mt-8"
      >Log in</router-link
    >
  </div>
</template>

<script>
  export default {
    name: 'PasswordRecovery',
    data: function () {
      return {
        passwordChanged: false,
        passwordChangeFailed: false,
        model: {
          username: this.$route.query.username,
          email: this.$route.query.email,
          password: '',
          password_confirmation: '',
        },
        form: {},
        fields: [
          {
            key: 'username',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'text',
              label: 'Username',
              layout: 'full',
            },
          },
          {
            key: 'email',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'email',
              label: 'Email Address',
              layout: 'full',
            },
          },
          {
            key: 'password',
            type: 'input',
            rules: 'required|password:@password_confirmation',
            templateOptions: {
              type: 'password',
              label: 'New Password',
              layout: 'full',
            },
          },
          {
            key: 'password_confirmation',
            type: 'input',
            rules: 'required',
            templateOptions: {
              type: 'password',
              label: 'Repeat New Password',
              layout: 'full',
            },
          },
        ],
      };
    },
    methods: {
      sanitizeForm() {
        this.model.password = '';
        this.model.password_confirmation = '';
      },

      handlePasswordReset() {
        this.$store
          .dispatch('RESET_PASSWORD', {
            ...this.model,
            token: this.$route.params.token,
          })
          .then(() => {
            this.passwordChanged = true;
            this.sanitizeForm();
          })
          .catch(() => {
            this.passwordChangeFailed = true;
            this.sanitizeForm();
          });
      },
    },
  };
</script>
